// @ts-strict-ignore
import PinnedPosts from 'src/modules/community/shared/components/sidebar/pinnedPosts';
import AboutUs from 'src/modules/community/communityHome/components/widgets/communityHomeAboutUs';
import Events from 'src/modules/community/communityHome/components/widgets/communityHomeEvents';
import PromoImage from 'src/modules/community/communityHome/components/widgets/communityHomePromoImage';
import SupportUs from 'src/modules/community/communityHome/components/widgets/communityHomeSupportUs';
import LatestPoll from 'src/modules/community/shared/components/sidebar/communityLatestPoll';
import { useSelector } from 'src/store/store';
export default function CommunityHomeWidgets() {
  const {
    widgets
  } = useSelector(({
    pages
  }) => pages.communityHome);
  return <div className="community__widgets" data-sentry-component="CommunityHomeWidgets" data-sentry-source-file="communityHomeWidgets.tsx">
      {widgets.map(widget => {
      switch (widget.widgetType) {
        case 'Events':
          return <Events events={widget.events} key="widget-events" />;
        case 'ContactUs':
          return <AboutUs key="widget-aboutUs" widget={widget} />;
        case 'PinnedPosts':
          if (!widget.posts || widget.posts.length === 0) {
            return null;
          }
          return <PinnedPosts key="widget-pinnedPosts" posts={widget.posts} />;
        case 'Campaign':
          return <PromoImage key="widget-promoImage" widget={widget} />;
        case 'SupportUs':
          return <SupportUs key="widget-supportUs" widget={widget} />;
        case 'LatestPoll':
          return <LatestPoll key="widget-latestPoll" widget={widget} />;
        default:
          return null;
      }
    })}
    </div>;
}