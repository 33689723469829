import Link from 'next/link';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import PostItem from 'src/modules/shared/resultsList/components/postItem';
import FeaturedPost from 'src/modules/community/shared/components/featuredPosts/featuredPost';
import CommunityFooterCTA from 'src/modules/community/shared/components/communityFooterCTA';
import PostListLoader from 'src/modules/shared/resultsList/components/postListLoader';
import PostFilterDropdown from 'src/modules/community/communityHome/components/postFilterDropdown';
import KeepReading from 'src/modules/community/communityHome/components/keepReading';
import Icon from 'src/components/icon';
import { ResultsSeeMoreCTA, ResultsList } from 'styles-js/resultsList';
import { useFetchFeaturedPosts } from 'src/utils/featuredPosts';
export default function CommunityHomeContent() {
  const {
    currentCommunity
  } = useSelector(({
    context
  }) => context);
  const {
    filter,
    widgets,
    isPostListLoading,
    posts
  } = useSelector(({
    pages
  }) => pages.communityHome);
  const [featuredPostTop, featuredPostBottom] = useFetchFeaturedPosts();
  const pinnedPostsWidget = widgets.find(({
    widgetType
  }: {
    widgetType: string;
  }) => widgetType === 'PinnedPosts');
  const isPinnedPostsLinkEnabled = pinnedPostsWidget?.posts?.length > 0;
  return <>
      {isPinnedPostsLinkEnabled && <Link as={`/${currentCommunity?.slug}/posts#pinned`} className="community__home-pinned-button" href={`/community/communityPosts?communitySlug=${currentCommunity?.slug}`} onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'pinned-posts',
        clickMetadata: {
          section: Section.MobileShortcut
        }
      });
    }}>
          <div>{i18n.t('Pinned posts')}</div>
          <Icon icon="open-right" />
        </Link>}
      <KeepReading data-sentry-element="KeepReading" data-sentry-source-file="communityHomeContent.tsx" />
      <div className="community__home-filter">
        <PostFilterDropdown filter={filter} data-sentry-element="PostFilterDropdown" data-sentry-source-file="communityHomeContent.tsx" />
      </div>
      {isPostListLoading ? <PostListLoader /> : posts.length > 0 ? <ResultsList>
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[0]} />
            {featuredPostTop && <FeaturedPostContainer>
                <FeaturedPost featuredPost={featuredPostTop} key={featuredPostTop.featuredPostId} position="home-top" />
              </FeaturedPostContainer>}
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[1]} />
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[2]} />
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[3]} />
            {featuredPostBottom && <FeaturedPostContainer>
                <FeaturedPost featuredPost={featuredPostBottom} key={featuredPostBottom.featuredPostId} position="home-bottom" />
              </FeaturedPostContainer>}
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[4]} />
            <ResultsSeeMoreCTA>
              <Link as={`/${currentCommunity?.slug}/posts`} href={`/community/communityPosts?communitySlug=${currentCommunity?.slug}`} onClick={() => trackFootfallEvent(Event.Clicked, {
          clickType: 'see-all-posts',
          clickMetadata: {
            section: Section.CommunityHome
          }
        })}>
                {i18n.t('See all posts')} <Icon icon="open-right" />
              </Link>
            </ResultsSeeMoreCTA>
          </ResultsList> : !currentCommunity?.isArchived && <div className="community__home-empty">
              <span>
                {i18n.t('Nothing here yet! Be the first to ')}
                <Link as={`/${currentCommunity?.slug}/posts/write`} href={`/community/write?communitySlug=${currentCommunity?.slug}`}>
                  {i18n.t('share your experience')}
                </Link>
                !
              </span>
            </div>}
      <CommunityFooterCTA data-sentry-element="CommunityFooterCTA" data-sentry-source-file="communityHomeContent.tsx" />
    </>;
}
const FeaturedPostContainer = styled.div`
  padding: 25px;
  border-bottom: ${({
  theme
}) => theme.greyLine};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 20px;
  }
`;